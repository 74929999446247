<template>
  <div class="i-left-panel shadow-one">
    <workspace-left-menu />
    <router-link :to="{name: 'workspace'}">
      <button
          class="ws-folder-button focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three shadow-one mt-5 w-full rounded flex items-center justify-center">
        Back to workspace
      </button>
    </router-link>
    <div class="ws-folder-box i-border-b-1 border-secondary-two overflow-y-auto">
      <div class="flex justify-between">
        <h2 class="fs-14 font-poppins cursor-pointer text-primary-one opacity-60 fw-600">All Notifications</h2>
        <h2 class="fs-14 font-poppins opacity-60 fw-600 text-primary-one">12</h2>
      </div>
      <div class="flex justify-between pt-8">
        <h2 class="fs-14 font-poppins cursor-pointer text-primary-one opacity-60 fw-600">Invites</h2>
        <h2 class="fs-14 font-poppins opacity-60 fw-600 text-primary-one">12</h2>
      </div>
      <div class="flex justify-between pt-8">
        <h2 class="fs-14 font-poppins cursor-pointer text-primary-one opacity-60 fw-600">Permission </h2>
        <h2 class="fs-14 font-poppins opacity-60 fw-600 text-primary-one">12</h2>
      </div>
      <div class="flex justify-between pt-8">
        <h2 class="fs-14 font-poppins cursor-pointer text-primary-one opacity-60 fw-600">Unread  </h2>
        <h2 class="fs-14 font-poppins opacity-60 fw-600 text-primary-one">12</h2>
      </div>
    </div>
    <div class="ws-share-box">
      <h2 class="fs-14 font-poppins text-secondary-one">Publish Project</h2>
      <h2 class="fs-14 font-poppins text-secondary-one mt-5">Share on Instrat Community</h2>
      <h2 class="fs-14 font-poppins text-secondary-one mt-5">Invite collaborators</h2>
      <div class="social-links mt-6 flex">
        <img src="/images/icons/workspace/facebook.svg" alt="facebook" class="cursor-pointer ws-social-icon">
        <img src="/images/icons/workspace/google.svg" alt="google" class="i-ml-25 cursor-pointer ws-social-icon">
        <img src="/images/icons/workspace/linked_in.svg" alt="linked_in" class="i-ml-25 cursor-pointer ws-social-icon">
        <img src="/images/icons/workspace/youtube.svg" alt="youtube" class="i-ml-25 cursor-pointer ws-social-icon">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftPart"
}
</script>

<style scoped>

</style>
