<template>
  <div class="relative flex">
    <left-part />
    <div class="i-right-panel">
      <div class="ws-heading-box relative flex flex-col pb-0">
        <workspace-menu />
        <!-- <router-link :to="{name: 'profile'}">
          <h2 class="font-poppins fs-14 fw-700 text-secondary-one absolute page-heading-title flex">Go to profile <img src="/images/icons/i_right_arrow.svg" alt="icon" class=" ml-2"></h2>
        </router-link> -->
      </div>

      <div class="bg-primary-three shadow-one mt-2 notification-box rounded">
        <div class="flex justify-between items-center py-3 i-border-b-1 border-secondary-two">
          <h2 class="fs-18 font-poppins fw-600 text-secondary-one pl-10">All Notification </h2>
          <div class="fs-14 font-poppins fw-600 text-primary-four cursor-pointer flex justify-end pr-10 mark-read-btn items-center" @click="markAllAsRead()">
            <img src="/images/icons/notification_check_circle.svg" alt="icon" class="mr-1">
            <h2>Mark all as read</h2>
          </div>
        </div>
        <div class="notifications overflow-y-auto">
          <div class="notification px-10 py-5 relative i-border-b-1 border-secondary-five" v-for="(notification, index) in notifications" :key="index" :class="{'bg-secondary-three': !notification.is_read}">
            <div class="flex justify-between">
              <h2 class="fs-18 font-poppins fw-600 text-secondary-one">{{ notification.notifiable ? notification.notifiable.title : '' }}</h2>
              <h2 class="fs-14 font-poppins fw-600 text-secondary-one">{{ $lodash.ago(notification.created_at) }}</h2>
            </div>
            <p class="break-words fs-16 font-poppins text-secondary-two pt-2">
              {{ notification.from.first_name }} {{ notification.notification }}
            </p>
            <button v-if="getLink(notification.type)"
                    @click="markAsRead(notification)"
                    class="focus-outline-none bg-secondary-two text-primary-three i-pl-30 i-pr-30 i-pt-10 i-pb-10 rounded mt-2 hover:bg-secondary-five fs-14 fw-600">Start Responding</button>
            <div class="absolute unread-box bg-primary-five" v-if="notification.is_read === 0"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftPart from "../../../components/notification/LeftPart";
import {mapGetters} from "vuex";
export default {
  name: "Index",
  components: {LeftPart},
  created() {
    const self = this;
    self.$store.dispatch("notification/getNotificationTypes");
    let user = localStorage.getItem("_user");
    if(user) {
      user = JSON.parse(user);
      if(user) {
        this.$store.dispatch("notification/getNotifications");
      }
    }
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      notifications: "notification/notifications",
      types: "notification/types",
    }),
  },
  methods: {
    getLink(type) {
      let notiType = this.types.find(nt => parseInt(type) === parseInt(nt.type));
      if(notiType) {
        return notiType.is_link;
      }
    },
    markAllAsRead() {
      this.$store.dispatch("notification/markAllAsRead");
    },
    markAsRead(notification) {
      notification.is_read = 1;
      this.$store.dispatch("notification/markAsRead", notification);
      this.$router.push(`/${notification.link}?from_notification=1`);
    }
  }
}
</script>

<style scoped>
.notification-box {
  margin-left: 40px;
  margin-right: 65px;
}
.mark-read-btn {
  width: 300px;
}
.notifications {
  height: 810px;
}
.unread-box {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 12px;
  right: 40px;
}
</style>
